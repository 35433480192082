@tailwind base;
@tailwind components;
@tailwind utilities;
@import "react-phone-input-2/lib/style.css";

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.shadow-glow {
  box-shadow: none;
}

.hover\:shadow-glow:hover {
  box-shadow:
    0 0 10px rgba(128, 128, 128, 0.6),
    0 0 10px rgba(128, 128, 128, 0.4),
    0 0 10px rgba(128, 128, 128, 0.2)
}

/* .motion-element {
  transition: box-shadow 0.15s ease-in-out;
} */